import React, {ReactElement} from "react";
import {Box, Flex, HStack, Icon, SimpleGrid, Stack, Text} from "@chakra-ui/react";
import {MdGroups, MdLocationCity, MdPerson} from "react-icons/md";

interface FeatureProps {
  title: string
  text: string
  icon: ReactElement
  video: JSX.Element
}

const Feature = ({title, text, icon, video}: FeatureProps) => {
  return (
    <Stack paddingBottom={"2rem"}>
      <HStack
        mb={"0.5rem"}
      >
        <Flex
          w={16}
          h={16}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={"gray.600"}
          mb={1}
          mr={"0.5rem"}
        >
          {icon}
        </Flex>
        <Text fontSize={"xl"} fontWeight={600}>{title}</Text>
      </HStack>
      <Box
        position={"relative"}
        height={"300px"}
        // rounded={"2xl"}
        boxShadow={"2xl"}
        // width={"full"}
        // overflow={"hidden"}
      >
        {/*<IconButton*/}
        {/*  aria-label={"Play Button"}*/}
        {/*  variant={"ghost"}*/}
        {/*  _hover={{bg: "transparent"}}*/}
        {/*  icon={<PlayIcon w={12} h={12}/>}*/}
        {/*  size={"lg"}*/}
        {/*  color={"gray"}*/}
        {/*  position={"absolute"}*/}
        {/*  left={"50%"}*/}
        {/*  top={"50%"}*/}
        {/*  transform={"translateX(-50%) translateY(-50%)"}*/}
        {/*/>*/}
        <Box
          // fit={"contain"}
          // align={"center"}
          w={"100%"}
          h={"100%"}
        >
          {video}
        </Box>
        {/*<Image*/}
        {/*  fit={"contain"}*/}
        {/*  align={"center"}*/}
        {/*  w={"100%"}*/}
        {/*  h={"100%"}*/}
        {/*  src={*/}
        {/*    "./assets/sync-agendas.png"*/}
        {/*  }*/}
        {/*/>*/}
      </Box>
      <Text paddingTop={"1rem"} color={"gray.600"} fontWeight={500}>{text}</Text>
    </Stack>
  )
}

export function SimpleThreeColumns() {
  return (
    <Box p={4}>
      <SimpleGrid columns={{base: 1, md: 3}} spacing={10}>
        <Feature
          icon={<Icon as={MdLocationCity} w={10} h={10}/>}
          title={"For Organizations"}
          text={
            "Align around what to accomplish next for an organization or simply gauge the pulse of your community."
          }
          video={embeddedYouTubeVideo("https://www.youtube.com/embed/Q1FFT9oneU4?si=8LX-Jkm2mko_9AU_")}
        />
        <Feature
          icon={<Icon as={MdGroups} w={10} h={10}/>}
          title={"For Teams"}
          text={
            "Reduce the number of meetings to make them more effective, or simply align on a challenging topic."
          }
          video={embeddedYouTubeVideo("https://www.youtube.com/embed/5M_n_ZxTWJw?si=hJzBOn1nYPaobvjg")}
        />
        <Feature
          icon={<Icon as={MdPerson} w={10} h={10}/>}
          title={"For Individuals"}
          text={
            "Organize and inform your ideas, to-dos, and decisions. Or check-in with yourself and track " +
            "how you're doing. "
          }
          video={embeddedYouTubeVideo("https://www.youtube.com/embed/7gucYIKgqZw?si=shY0R5uddqswJFoY")}
        />
      </SimpleGrid>
    </Box>
  )

  function embeddedYouTubeVideo(src: string) {
    return <iframe
      width="100%"
      height="100%"
      src={src}
      title="YouTube video player" frameBorder="0"
      allow=
        "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen></iframe>;
  }
}