"use client"

import {Container, Flex, Heading, Image, SimpleGrid, Stack, Text, useColorModeValue,} from "@chakra-ui/react"

// interface FeatureProps {
//   text: string
//   iconBg: string
//   icon?: ReactElement
// }

// const Feature = ({text, icon, iconBg}: FeatureProps) => {
//   return (
//     <Stack direction={"row"} align={"center"}>
//       <Flex w={8} h={8} align={"center"} justify={"center"} rounded={"full"} bg={iconBg}>
//         {icon}
//       </Flex>
//       <Text fontWeight={600}>{text}</Text>
//     </Stack>
//   )
// }

export default function AboutUs() {
  return (
    <Container maxW={"5xl"} py={12}>
      <SimpleGrid columns={{base: 1, md: 2}} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            rounded={"md"}>
            Our Story
          </Text>
          <Heading>Why We Created Sentiment</Heading>
          <Text color={"gray.500"} fontSize={"lg"}>
            Whether at a two-person startup or a global tech behemoth, at an individual contributor level or in
            the C-suite, organizations and communities often suffer from poorly informed decisions. We have experienced
            this firsthand, and it has motivated us to create Sentiment, a platform that helps synthesize input from
            multiple voices, empowers nuance, and facilitates alignment -- all so that individuals and leaders alike can
            inform better decisions.
          </Text>
          {/*<Stack*/}
          {/*  spacing={4}*/}
          {/*  divider={*/}
          {/*    <StackDivider borderColor={useColorModeValue("gray.100", "gray.700")}/>*/}
          {/*  }>*/}
          {/*  <Feature*/}
          {/*    icon={<Icon as={IoAnalyticsSharp} color={"yellow.500"} w={5} h={5}/>}*/}
          {/*    iconBg={useColorModeValue("yellow.100", "yellow.900")}*/}
          {/*    text={"Business Planning"}*/}
          {/*  />*/}
          {/*  <Feature*/}
          {/*    icon={<Icon as={IoLogoBitcoin} color={"green.500"} w={5} h={5}/>}*/}
          {/*    iconBg={useColorModeValue("green.100", "green.900")}*/}
          {/*    text={"Financial Planning"}*/}
          {/*  />*/}
          {/*  <Feature*/}
          {/*    icon={<Icon as={IoSearchSharp} color={"purple.500"} w={5} h={5}/>}*/}
          {/*    iconBg={useColorModeValue("purple.100", "purple.900")}*/}
          {/*    text={"Market Analysis"}*/}
          {/*  />*/}
          {/*</Stack>*/}
        </Stack>
        <Flex>
          <Image
            rounded={"md"}
            src={"/assets/about_us_bg.png"}
            objectFit={"cover"}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  )
}